


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Moment } from 'moment';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import HorizontalMenu from '@/_modules/controls/components/horizontal-menu/horizontal-menu.vue';
import HorizontalMenuItem from '@/_modules/controls/components/horizontal-menu-item/horizontal-menu-item.vue';
import HorizontalMenuArrowLeft
  from '@/_modules/controls/components/horizontal-menu-arrow-left/horizontal-menu-arrow-left.vue';
import HorizontalMenuArrowRight
  from '@/_modules/controls/components/horizontal-menu-arrow-right/horizontal-menu-arrow-right.vue';
import Avatar from '@/_components/avatar/avatar.vue';
import { TContact } from '@/_types/contact.type';
import FileHelper from '@/_helpers/file.helper';
import IconZoomOut from '@/_modules/icons/components/icon-zoom-out.vue';
import ProgramMediaBlock from '@/_modules/promo-program/components/program-media-block/program-media-block.vue';
import PromoBroadcastMenu from '@/_modules/promo/components/promo-broadcast-menu/promo-broadcast-menu.vue';
import { TEvent } from '@/_types/event.type';
import { TUser } from '@/_types/user.type';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import ProgramChat from '@/_modules/promo-program/components/program-chat/program-chat.vue';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import { TChatGroup } from '@/_modules/chat/types/chat-group.type';
import { ConferenceProgramChatType } from '@/_modules/promo-program/types/conference-program-chat-type.enum';
import HorizontalMenuItemLink
  from '@/_modules/controls/components/horizontal-menu-item-link/horizontal-menu-item-link.vue';
import { TChatGroupState } from '@/_modules/chat/types/chat-group-state.type';
import PromoContact from '@/views/components/promoPage/contacts/contact.vue';
import IconStar from '@/_modules/icons/components/icon-star.vue';
import biletumSharer from '@/views/components/popups/biletumSharer.vue';
import IconShare from '@/_modules/icons/components/icon-share.vue';

@Component({
  name: 'promo-program-details',
  components: {
    HorizontalMenu,
    HorizontalMenuItem,
    HorizontalMenuItemLink,
    HorizontalMenuArrowLeft,
    HorizontalMenuArrowRight,
    Avatar,
    IconZoomOut,
    ProgramMediaBlock,
    PromoBroadcastMenu,
    ProgramChat,
    PromoContact,
    IconStar,
    biletumSharer,
    IconShare,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      user: '_userStore/user',
      contact: 'promoPageStore/contact',
      getChatGroupState: 'chatStore/getChatGroupState',
      getProgramById: 'promoProgramStore/getProgramById',
    }),
  }
})
export default class PromoProgramDetails extends Vue {

  public readonly BroadcastType: typeof BroadcastType = BroadcastType;

  @Prop({ type: Object })
  public readonly program: TConferenceProgram;

  public readonly user: TUser;
  public readonly event: TEvent;
  public readonly contact: TContact;
  public readonly getChatGroupState: (groupId: string) => TChatGroupState;
  public readonly getProgramById: (programId: number) => TConferenceProgram;

  public fullscreenMode: boolean = false;
  public isPromoBroadcastMenuVisible: boolean = false;
  public isUnpinActionDisabled: boolean = true;

  private destroyed$: Subject<void> = new Subject<void>();

  public mounted(): void {
    this.subscribeToPageEvents();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get programState(): TConferenceProgram {
    return (this.program && this.program.id && this.getProgramById(this.program.id)) || null;
  }

  public get date(): string {
    return this.$route.params.date || null;
  }

  public get programId(): number {
    return (this.program && this.program.id) || null;
  }

  public get contactId(): number {
    return (this.$route.params.contact_id && parseInt(this.$route.params.contact_id, 10)) || null;
  }

  public get selectedSpeaker(): TContact {
    const contactId = this.contactId;
    if (!this.contactId || !this.program || !this.program.speakers || !this.program.speakers.length) {
      return null;
    }
    return this.program.speakers.find(speaker => speaker.id === contactId) || null;
  }

  public get liveChatGroup(): TChatGroup {
    return this.program.show_live_chat ? ChatHelper.getProgramChatGroup(this.program, ConferenceProgramChatType.LIVE) : null;
  }

  public get liveChatGroupState(): TChatGroupState {
    const liveChatGroup = this.liveChatGroup;
    return liveChatGroup ? this.getChatGroupState(liveChatGroup.id) : null;
  }

  public get speakerChatGroup(): TChatGroup {
    return this.program.show_speaker_chat ? ChatHelper.getProgramChatGroup(this.program, ConferenceProgramChatType.SPEAKER) : null;
  }

  public get speakerChatGroupState(): TChatGroupState {
    const speakerChatGroup = this.speakerChatGroup;
    return speakerChatGroup ? this.getChatGroupState(speakerChatGroup.id) : null;
  }

  public get isAnyChatOn(): boolean {
    return this.program && (this.program.show_speaker_chat || this.program.show_live_chat);
  }

  public get isAnyChatActive(): boolean {
    return !!this.liveChatGroupState || !!this.speakerChatGroupState;
  }

  public get dateStartMoment(): Moment {
    return (this.program && this.program.date_start) ? this.$moment(this.program.date_start.replace('Z', '')) : null;
  }

  public get dateEndMoment(): Moment {
    return (this.program && this.program.date_end) ? this.$moment(this.program.date_end.replace('Z', '')) : null;
  }

  public get timeText(): string {
    const dateStartMoment = this.dateStartMoment;
    const dateEndMoment = this.dateEndMoment;
    let result = '';
    if (dateStartMoment) {
      result += dateStartMoment.format(DateTimeFormat.HH_MM);
    }
    if (dateEndMoment) {
      if (result) {
        result += ' - ';
      }
      result += dateEndMoment.format(DateTimeFormat.HH_MM);
    }
    if (dateStartMoment) {
      if (result) {
        result += ', ';
      }
      result += dateEndMoment.format(DateTimeFormat.MONTH_DATE);
    }
    return result;
  }

  public get canStartBroadcast(): boolean {

    if (!this.user || !this.event || !this.contact || !this.program) {
      return false;
    }

    if (this.event.creator_user_id === this.user.id) {
      return true;
    }

    if (this.program.speakers && this.program.speakers.length) {
      if (this.program.speakers.find(speaker => speaker.id === this.contact.id)) {
        return true;
      }
    }

    return false;
  }

  public get shareUrl(): string {
    return location.origin + this.$router.resolve({
      name: 'promo-program-date-program',
      params: {
        eventId: '' + this.eventId,
        date: this.date,
        programId: '' + this.programId,
      }
    }).href;
  }

  public onShareClick(): void {
    if (!this.$refs.sharer) {
      return;
    }
    (this.$refs.sharer as any).showSharer();
  }

  public toggleFavorite(): void {
    const programState = this.programState;
    if (!programState) {
      return;
    }
    this.$store.dispatch('promoProgramStore/toggleFavorite', {
      eventId: this.eventId,
      conferenceRoomId: this.program.conference_id,
      programId: this.program.id,
      isFavorite: !programState.is_favorite,
    });
  }

  public onActionChatClick(): void {
    try {
      this.$router.push({
        name: 'promo-program-date-program',
        params: {
          eventId: '' + this.eventId,
          date: this.date,
          programId: '' + this.programId,
        }
      });
    } catch {
      /* ignore */
    }
  }

  public onActionBroadcastClick(event: MouseEvent): void {
    event.stopPropagation();
    this.isPromoBroadcastMenuVisible = !this.isPromoBroadcastMenuVisible;
  }

  public setFullscreenMode(isEnabled: boolean): void {
    this.fullscreenMode = isEnabled;
  }

  public onActionCloseClick(): void {
    this.$router.push({
      name: 'promo-program-date',
      params: Object.assign({}, this.$route.params, { programId: undefined }),
    });
  }

  public fileExt(fileName: string): string {
    return FileHelper.getFileExtension(fileName);
  }

  public onUnpinClick(): void {
    if (this.isUnpinActionDisabled || !this.program || !this.program.activeMediaItem) {
      return;
    }

    const activeMediaItem = this.program.activeMediaItem;
    if (!activeMediaItem) {
      return;
    }
    const node = document.getElementById('program-media-item-' + activeMediaItem.id);

    this.$store.dispatch('promoProgramStore/unpinMediaItem', {
      programId: this.program.id,
      id: activeMediaItem.id,
    });

    if (node && (activeMediaItem.type === 'video' || activeMediaItem.type === 'embed')) {
      this.$nextTick(() => {
        const container = document.getElementById('media-item-container-' + activeMediaItem.id);
        if (container) {
          container.append(node);
        }
      });
    }
  }

  @Watch('program', { immediate: true, deep: true })
  private onProgramChange(): void {
    this.isUnpinActionDisabled = !this.program || !this.program.activeMediaItem || this.program.activeMediaItem.type === 'image';
  }

  // @Watch('programState', { immediate: true, deep: true })
  // private onProgramStateChange(): void {
  //   console.log('');
  //   console.log('onProgramStateChange', this.programState);
  //   console.log('');
  // }

  private subscribeToPageEvents(): void {
    fromEvent<MouseEvent>(document, 'click')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onDocumentClick);
  }

  private onDocumentClick(): void {
    this.isPromoBroadcastMenuVisible = false;
  }

}
