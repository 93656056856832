


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Location } from 'vue-router';
import { Moment } from 'moment';

import PromoHead from '@/views/components/promoPage/head.vue';
import HorizontalMenu from '@/_modules/controls/components/horizontal-menu/horizontal-menu.vue';
import HorizontalMenuArrowLeft
  from '@/_modules/controls/components/horizontal-menu-arrow-left/horizontal-menu-arrow-left.vue';
import HorizontalMenuArrowRight
  from '@/_modules/controls/components/horizontal-menu-arrow-right/horizontal-menu-arrow-right.vue';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import { TEvent } from '@/_types/event.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import HorizontalMenuItemLink
  from '@/_modules/controls/components/horizontal-menu-item-link/horizontal-menu-item-link.vue';
import HorizontalMenuItem from '@/_modules/controls/components/horizontal-menu-item/horizontal-menu-item.vue';
import HorizontalMenuArrowLeftLink
  from '@/_modules/controls/components/horizontal-menu-arrow-left-link/horizontal-menu-arrow-left-link.vue';
import HorizontalMenuArrowRightLink
  from '@/_modules/controls/components/horizontal-menu-arrow-right-link/horizontal-menu-arrow-right-link.vue';
import PromoProgramListItem from '@/_modules/promo-program/components/promo-program-list-item/promo-program-list-item.vue';
import PromoProgramDetails from '@/_modules/promo-program/components/promo-program-details/promo-program-details.vue';
import UtilsHelper from '@/_helpers/utils.helper';
import IconSearch from '@/_modules/icons/components/icon-search.vue';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

const SEARCH_DEBOUNCE_TIME = 1000;

type TConferenceRoomProgram = {
  id: number;
  title: string;
  programs: TConferenceProgram[];
};

type TProgramByDate = {
  date: Moment;
  dateText: string;
  dateParam: string;
  rooms: TConferenceRoomProgram[];
};

@Component({
  name: 'promo-program',
  components: {
    PromoHead,
    HorizontalMenu,
    HorizontalMenuItem,
    HorizontalMenuArrowLeft,
    HorizontalMenuArrowRight,
    HorizontalMenuItemLink,
    HorizontalMenuArrowLeftLink,
    HorizontalMenuArrowRightLink,
    PromoProgramListItem,
    PromoProgramDetails,
    IconSearch,
  },
  computed: {
    ...mapGetters({
      isProgramLoading: 'promoProgramStore/isLoading',
      conferenceRooms: 'promoProgramStore/conferenceRooms',
      lastError: 'promoProgramStore/lastError',
      event: '_eventStore/event',
    }),
  }
})
export default class PromoProgram extends Vue {

  public readonly isProgramLoading: boolean;
  public readonly conferenceRooms: TConferenceRoom[];
  public readonly event: TEvent;
  public readonly lastError: Error;

  public currentDateMoment: Moment;
  public programByDates: TProgramByDate[] = [];
  public selectedDate: Moment = null;
  public selectedDateParam: string = null;

  public visibleProgramByDates: TProgramByDate[] = [];
  public selectedProgramByDate: TProgramByDate = null;

  public visibleConferenceRoomPrograms: TConferenceRoomProgram[] = [];
  public firstVisibleConferenceRoomProgramIndex: number = 0;

  public selectedConferenceRoomProgram: TConferenceRoomProgram = null;
  public selectedProgram: TConferenceProgram = null;

  public searchString: string = '';

  private destroyed$: Subject<void> = new Subject<void>();
  private updateSearch$: Subject<void> = new Subject<void>();

  public created(): void {
    this.currentDateMoment = this.$moment();

    this.updateSearch$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(SEARCH_DEBOUNCE_TIME),
    ).subscribe(() => {
      this.setQuerySearch();
    });
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get date(): string {
    return this.$route.params.date || null;
  }

  public get programId(): number {
    return this.$route.params.programId ? parseInt(this.$route.params.programId, 10) : null;
  }

  public get queryType(): 'all' | 'my' {
    return this.$route.query.type === 'my' ? 'my' : 'all';
  }

  public get querySearch(): string {
    return ('' + this.$route.query.search) || '';
  }

  public get selectedProgramByDateIndex(): number {
    return this.programByDates.indexOf(this.selectedProgramByDate);
  }

  public get isDatesMenuArrowsVisible(): boolean {
    return this.programByDates.length > 3;
  }

  public get isDatesMenuArrowLeftDisabled(): boolean {
    return this.selectedProgramByDateIndex < 1;
  }

  public get isDatesMenuArrowRightDisabled(): boolean {
    return this.selectedProgramByDateIndex >= this.programByDates.length - 1;
  }

  public get datesMenuArrowLeftTo(): Location {
    if (!this.programByDates || !this.programByDates.length) {
      return {
        name: 'promo-program',
        query: this.$route.query
      };
    }
    const currentIndex = this.selectedProgramByDateIndex;
    const nextIndex = currentIndex - 1;
    return (nextIndex > -1 && nextIndex < this.programByDates.length ) ? {
      name: 'promo-program-date',
      params: {
        date: this.programByDates[nextIndex].dateParam,
      },
      query: this.$route.query
    } : {
      name: 'promo-program-date',
      params: {
        date: this.programByDates[currentIndex].dateParam,
      },
      query: this.$route.query
    };
  }

  public get datesMenuArrowRightTo(): Location {
    if (!this.programByDates || !this.programByDates.length) {
      return {
        name: 'promo-program',
        query: this.$route.query
      };
    }
    const currentIndex = this.selectedProgramByDateIndex;
    const nextIndex = currentIndex + 1;
    return (nextIndex > -1 && nextIndex < this.programByDates.length) ? {
      name: 'promo-program-date',
      params: {
        date: this.programByDates[nextIndex].dateParam,
      },
      query: this.$route.query
    } : {
      name: 'promo-program-date',
      params: {
        date: this.programByDates[currentIndex].dateParam,
      },
      query: this.$route.query
    };
  }

  public get isConferenceRoomsMenuArrowsVisible(): boolean {
    if (!this.selectedProgramByDate) {
      return false;
    }
    return this.selectedProgramByDate.rooms.length > 3;
  }

  public get isConferenceRoomsMenuArrowLeftDisabled(): boolean {
    if (!this.selectedProgramByDate) {
      return true;
    }
    return this.firstVisibleConferenceRoomProgramIndex < 1;
  }

  public get isConferenceRoomsMenuArrowRightDisabled(): boolean {
    if (!this.selectedProgramByDate) {
      return true;
    }
    return this.firstVisibleConferenceRoomProgramIndex >= this.selectedProgramByDate.rooms.length - 3;
  }

  public setQuerySearch(): void {
    const querySearch = this.querySearch || '';
    const searchString = this.searchString.trim();
    if (searchString === querySearch) {
      return;
    }

    const date = this.date;
    const queryType = this.queryType;
    if (date) {
      this.$router.push({
        name: 'promo-program-date',
        params: {
          eventId: '' + this.eventId,
          date: date,
        },
        query: {
          type: queryType === 'my' ? 'my' : undefined,
          search: searchString || undefined,
        },
      });
    } else {
      this.$router.push({
        name: 'promo-program',
        params: {
          eventId: '' + this.eventId,
        },
        query: {
          type: queryType === 'my' ? 'my' : undefined,
          search: searchString || undefined,
        },
      });
    }
  }

  public setQueryType(type: 'all' | 'my'): void {
    if (this.queryType === type) {
      return;
    }
    const date = this.date;
    const querySearch = this.querySearch;
    if (date) {
      this.$router.push({
        name: 'promo-program-date',
        params: {
          eventId: '' + this.eventId,
          date: date,
        },
        query: {
          type: type === 'my' ? 'my' : undefined,
          search: querySearch || undefined,
        },
      });
    } else {
      this.$router.push({
        name: 'promo-program',
        params: {
          eventId: '' + this.eventId,
        },
        query: {
          type: type === 'my' ? 'my' : undefined,
          search: querySearch || undefined,
        },
      });
    }
  }

  public onConferenceRoomsMenuArrowLeftClick(): void {
    if (!this.selectedProgramByDate || this.firstVisibleConferenceRoomProgramIndex < 1) {
      return;
    }
    this.firstVisibleConferenceRoomProgramIndex--;
    this.setVisibleConferenceRoomPrograms();
  }

  public onConferenceRoomsMenuArrowRightClick(): void {
    if (
      !this.selectedProgramByDate
      || this.firstVisibleConferenceRoomProgramIndex >= this.selectedProgramByDate.rooms.length - 3
    ) {
      return;
    }
    this.firstVisibleConferenceRoomProgramIndex++;
    this.setVisibleConferenceRoomPrograms();
  }

  @Watch('event', { immediate: true })
  private onEventChange(): void {
    this.setProgramByDates();
  }

  @Watch('conferenceRooms', { immediate: true })
  private onConferenceRoomsChange(): void {
    this.setProgramByDates();
  }

  @Watch('date', { immediate: true })
  private onDateChange(): void {
    this.applyNavigation();
  }

  @Watch('conferenceRoomId', { immediate: true })
  private onConferenceRoomIdChange(): void {
    this.applyNavigation();
  }

  @Watch('programId', { immediate: true })
  private onProgramIdChange(): void {
    this.applyNavigation();
  }

  @Watch('queryType')
  private onQueryTypeChange(): void {
    this.setProgramByDates();
  }

  @Watch('querySearch')
  private onQuerySearchChange(): void {
    this.setProgramByDates();
  }

  @Watch('searchString', { immediate: true })
  private onSearchStringChange(): void {
    this.updateSearch$.next();
  }

  private applyNavigation(): void {
    this.selectedProgramByDate = null;
    this.firstVisibleConferenceRoomProgramIndex = 0;
    this.selectedConferenceRoomProgram = null;
    this.selectedProgram = null;

    if (
      !this.event || !this.event.date_start || !this.event.date_end
      || !this.programByDates || !this.programByDates.length
    ) {
      // TODO: display something to user
      /* nothing to navigate */
      return;
    }

    // console.log('---------------------------------');
    // console.log('applyNavigation:');
    // console.log('eventId', this.eventId);
    // console.log('date', this.date);
    // console.log('conferenceRoomId', this.conferenceRoomId);
    // console.log('programId', this.programId);
    // console.log('---------------------------------');

    if (this.date) {
      const foundProgramByDate = this.programByDates.find(programByDate => {
        return programByDate.dateParam === this.date;
      });
      if (foundProgramByDate) {
        this.selectedProgramByDate = foundProgramByDate;
      } else {
        /* navigation parameter is not found, navigate to parent route */
        this.$router.push({
          name: 'promo-program',
          query: this.$route.query
        });
        return;
      }
    } else {
      /* select today or first */
      const todayProgramByDate = this.programByDates.find(programByDate => {
        return programByDate.date.isSame(this.currentDateMoment, 'date');
      });
      if (todayProgramByDate) {
        this.selectedProgramByDate = todayProgramByDate;
      } else {
        this.selectedProgramByDate = this.programByDates[0];
      }
    }

    this.selectedDate = this.selectedProgramByDate.date.clone();
    this.selectedDateParam = this.selectedDate.format(DateTimeFormat.YYYY_MM_DD);

    if (this.programId) {
      const programId = this.programId;
      let selectedConferenceRoomProgram;
      let selectedProgram;
      let selectedConferenceRoomProgramIndex = -1;
      for (let i = 0; i < this.selectedProgramByDate.rooms.length; i++) {
        for (let j = 0; j < this.selectedProgramByDate.rooms[i].programs.length; j++) {
          if (this.selectedProgramByDate.rooms[i].programs[j].id === programId) {
            selectedConferenceRoomProgramIndex = i;
            selectedConferenceRoomProgram = this.selectedProgramByDate.rooms[i];
            selectedProgram = this.selectedProgramByDate.rooms[i].programs[j];
            break;
          }
        }
        if (selectedProgram) {
          break;
        }
      }

      this.selectedConferenceRoomProgram = selectedConferenceRoomProgram || null;
      this.selectedProgram = selectedProgram || null;

      if (!this.selectedProgram) {
        this.$router.push({
          name: 'promo-program-date',
          params: {
            date: this.selectedDateParam,
          },
          query: this.$route.query
        });
        return;
      }

      if (selectedConferenceRoomProgramIndex > -1) {
        if (selectedConferenceRoomProgramIndex <= this.selectedProgramByDate.rooms.length - 3) {
          this.firstVisibleConferenceRoomProgramIndex = selectedConferenceRoomProgramIndex;
        }
      }
    }

    this.setVisibleProgramByDates();
    this.setVisibleConferenceRoomPrograms();
  }

  private setProgramByDates(): void {
    this.programByDates = [];

    if (!this.event || !this.event.date_start || !this.event.date_end) {
      return;
    }

    /* TODO: We're not working with time zones, why dates are in utc timezone? */
    const dateStartMoment = this.$moment(this.event.date_start.replace('Z', ''));
    const dateEndMoment = this.$moment(this.event.date_end.replace('Z', ''));

    if (dateEndMoment.isBefore(dateStartMoment)) {
      return;
    }

    dateStartMoment.hours(0).minutes(0).seconds(1);
    dateEndMoment.hours(23).minutes(59).seconds(59);
    const dateCounterMoment = dateStartMoment.clone().hours(12).seconds(0);

    do {

      const dateMoment = dateCounterMoment.clone();
      const dateProgram: TProgramByDate = {
        date: dateMoment,
        dateText: dateMoment.format(DateTimeFormat.MONTH_DATE),
        dateParam: dateMoment.format(DateTimeFormat.YYYY_MM_DD),
        rooms: [],
      };

      if (this.conferenceRooms && this.conferenceRooms.length) {
        this.conferenceRooms.forEach((conferenceRoom: TConferenceRoom): void => {

          if (!conferenceRoom.programs || !conferenceRoom.programs.length) {
            return;
          }

          const conferenceRoomProgram: TConferenceRoomProgram = {
            id: conferenceRoom.id,
            title: conferenceRoom.title,
            programs: [],
          };

          conferenceRoom.programs.forEach((program: TConferenceProgram): void => {
            /* TODO: We're not working with time zones, why dates are in utc timezone? */
            const programDateMoment: Moment = this.$moment(program.date_start.replace('Z', ''));
            programDateMoment.hours(12).minutes(0).seconds(0);

            if (programDateMoment.isSame(dateMoment, 'date')) {
              conferenceRoomProgram.programs.push(program);
            }
          });

          if (conferenceRoomProgram.programs.length > 0) {
            dateProgram.rooms.push(conferenceRoomProgram);
          }
        });
      }

      if (dateProgram.rooms.length > 0) {
        this.programByDates.push(dateProgram);
      }

      dateCounterMoment.add(1, 'day');

    } while (dateCounterMoment.isSameOrBefore(dateEndMoment, 'date'));

    this.applyQuery();
    this.sortProgramByDates();
    this.applyNavigation();
  }

  private applyQuery(): void {
    const queryType = this.queryType;
    const querySearch = this.querySearch;

    if (queryType === 'all' && !querySearch) {
      return;
    }

    let searchString = this.searchString.trim();
    let searchRegExp: RegExp = null;
    if (searchString) {
      searchString = UtilsHelper.escapeRegExp(searchString);
      searchRegExp = new RegExp(`${searchString}`, 'i');
    }

    for (let i = 0; i < this.programByDates.length; i++) {
      if (!this.programByDates[i].rooms || !this.programByDates[i].rooms.length) {
        continue;
      }
      for (let j = 0; j < this.programByDates[i].rooms.length; j++) {
        if (!this.programByDates[i].rooms[j].programs || !this.programByDates[i].rooms[j].programs.length) {
          continue;
        }
        this.programByDates[i].rooms[j].programs = this.programByDates[i].rooms[j].programs.filter(program => {
          if (queryType === 'my' && !program.is_favorite) {
            return false;
          }
          if (searchRegExp && !searchRegExp.exec(program.title)) {
            return false;
          }
          return true;
        });
      }
    }
  }

  private sortProgramByDates(): void {
    if (!this.programByDates || !this.programByDates.length) {
      return;
    }

    for (let i = 0; i < this.programByDates.length; i++) {
      if (!this.programByDates[i].rooms || !this.programByDates[i].rooms.length) {
        continue;
      }
      for (let j = 0; j < this.programByDates[i].rooms.length; j++) {
        if (!this.programByDates[i].rooms[j].programs || !this.programByDates[i].rooms[j].programs.length) {
          continue;
        }

        this.programByDates[i].rooms[j].programs.sort((a, b) => {
          if (a.date_start === b.date_start) {
            if (a.date_end === b.date_end) {
              return 0;
            } else if (a.date_end > b.date_end) {
              return 1;
            } else {
              return -1;
            }
          } else if (a.date_start > b.date_start) {
            return 1;
          } else {
            return -1;
          }
        });
      }
    }
  }

  private setVisibleProgramByDates(): void {
    // number of visible items is hardcoded to 3 or less

    this.visibleProgramByDates = [];
    if (this.programByDates.length <= 3) {
      this.visibleProgramByDates = [ ...this.programByDates ];
      return;
    }

    const selectedIndex = this.selectedProgramByDateIndex;
    /* edge cases first */
    if (selectedIndex >= this.programByDates.length - 1) {
      this.visibleProgramByDates = this.programByDates.slice(this.programByDates.length - 3);
    } else if (selectedIndex < 1) {
      this.visibleProgramByDates = this.programByDates.slice(0, 3);
    } else {
      this.visibleProgramByDates = this.programByDates.slice(
        selectedIndex - 1,
        selectedIndex + 2,
      );
    }
  }

  private setVisibleConferenceRoomPrograms(): void {
    // number of visible items is hardcoded to 3 or less

    this.visibleConferenceRoomPrograms = [];
    if (!this.selectedProgramByDate) {
      return;
    }

    const roomPrograms = this.selectedProgramByDate.rooms;
    if (roomPrograms.length <= 3) {
      this.visibleConferenceRoomPrograms = [ ...roomPrograms ];
      return;
    }

    if (this.firstVisibleConferenceRoomProgramIndex >= roomPrograms.length - 3) {
      this.visibleConferenceRoomPrograms = roomPrograms.slice(roomPrograms.length - 3);
    } else if (this.firstVisibleConferenceRoomProgramIndex < 1) {
      this.visibleConferenceRoomPrograms = roomPrograms.slice(0, 3);
    } else {
      this.visibleConferenceRoomPrograms = roomPrograms.slice(
        this.firstVisibleConferenceRoomProgramIndex,
        this.firstVisibleConferenceRoomProgramIndex + 3,
      );
    }
  }
}
