


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import ChatMessageComposer from '@/_modules/chat/components/chat-message-composer/chat-message-composer.vue';
import { ConferenceProgramChatType } from '@/_modules/promo-program/types/conference-program-chat-type.enum';
import { mapGetters } from 'vuex';
import { ChatConnectStatus } from '@/_modules/chat/types/chat-connect-status.enum';
import chatService from '@/_modules/chat/services/chat.service';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import { TChatGroup } from '@/_modules/chat/types/chat-group.type';
import { TChatGroupState } from '@/_modules/chat/types/chat-group-state.type';
import ChatMessages from '@/_modules/chat/components/chat-messages/chat-messages.vue';

@Component({
  name: 'program-chat',
  components: {
    ChatMessageComposer,
    ChatMessages,
  },
  computed: {
    ...mapGetters({
      connectStatus: 'chatStore/connectStatus',
      lastError: 'chatStore/lastError',
      isKickedOut: 'chatStore/isKickedOut',
      getChatGroupState: 'chatStore/getChatGroupState',
    }),
  },
})
export default class ProgramChat extends Vue {

  public readonly ConferenceProgramChatType: typeof ConferenceProgramChatType = ConferenceProgramChatType;

  @Prop({ type: Object })
  public readonly program: TConferenceProgram;

  public readonly connectStatus: ChatConnectStatus;
  public readonly lastError: Error;
  public readonly isKickedOut: boolean;
  public readonly getChatGroupState: (groupId: string) => TChatGroupState;

  public get isChatMessageComposerDisabled(): boolean {
    return !this.isConnected || this.isConnecting || this.isJoiningCurrentChat;
  }

  public get isJoiningCurrentChat(): boolean {
    if (this.selectedChatType === ConferenceProgramChatType.LIVE) {
      const liveChatGroupState = this.liveChatGroupState;
      return !liveChatGroupState || liveChatGroupState.isJoining;
    } else if (this.selectedChatType === ConferenceProgramChatType.SPEAKER) {
      const speakerChatGroupState = this.speakerChatGroupState;
      return !speakerChatGroupState || speakerChatGroupState.isJoining;
    }
    return true;
  }

  public get isEmptyCurrentChat(): boolean {
    if (this.selectedChatType === ConferenceProgramChatType.LIVE) {
      const liveChatGroupState = this.liveChatGroupState;
      return !liveChatGroupState || liveChatGroupState.isJoining || !liveChatGroupState.messages.length;
    } else if (this.selectedChatType === ConferenceProgramChatType.SPEAKER) {
      const speakerChatGroupState = this.speakerChatGroupState;
      return !speakerChatGroupState || speakerChatGroupState.isJoining || !speakerChatGroupState.messages.length;
    }
    return true;
  }

  public get liveChatGroup(): TChatGroup {
    return this.program.show_live_chat ? ChatHelper.getProgramChatGroup(this.program, ConferenceProgramChatType.LIVE) : null;
  }

  public get liveChatGroupState(): TChatGroupState {
    const liveChatGroup = this.liveChatGroup;
    return liveChatGroup ? this.getChatGroupState(liveChatGroup.id) : null;
  }

  public get speakerChatGroup(): TChatGroup {
    return this.program.show_speaker_chat ? ChatHelper.getProgramChatGroup(this.program, ConferenceProgramChatType.SPEAKER) : null;
  }

  public get speakerChatGroupState(): TChatGroupState {
    const speakerChatGroup = this.speakerChatGroup;
    return speakerChatGroup ? this.getChatGroupState(speakerChatGroup.id) : null;
  }

  public get isConnected(): boolean {
    return this.connectStatus === ChatConnectStatus.CONNECTED;
  }

  public get isConnecting(): boolean {
    return this.connectStatus === ChatConnectStatus.CONNECTING;
  }

  public get currentChatGroupState(): TChatGroupState {
    if (this.selectedChatType === ConferenceProgramChatType.LIVE) {
      return this.liveChatGroupState;
    } else if (this.selectedChatType === ConferenceProgramChatType.SPEAKER) {
      return this.speakerChatGroupState;
    }
    return null;
  }

  public selectedChatType: ConferenceProgramChatType = null;

  public mounted(): void {

    if (this.program.show_live_chat) {
      this.selectChatType(ConferenceProgramChatType.LIVE);
    } else {
      this.selectChatType(ConferenceProgramChatType.SPEAKER);
    }

  }

  public async onJoinClick(): Promise<void> {
    await chatService.connect();
    if (this.program.show_live_chat) {
      chatService.joinChatGroup(this.liveChatGroup);
    }
    if (this.program.show_speaker_chat) {
      chatService.joinChatGroup(this.speakerChatGroup);
    }
  }

  public selectChatType(chatType: ConferenceProgramChatType): void {
    if (chatType === ConferenceProgramChatType.LIVE) {
      if (!this.program.show_live_chat) {
        return;
      }
      this.selectedChatType = ConferenceProgramChatType.LIVE;
    } else if (chatType === ConferenceProgramChatType.SPEAKER) {
      if (!this.program.show_speaker_chat) {
        return;
      }
      this.selectedChatType = ConferenceProgramChatType.SPEAKER;
    }
  }

}
