


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import StreamPlayer from '@/_components/stream-player/stream-player.vue';
import { TMediaItem } from '@/_modules/events/types/media-item.type';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';

@Component({
  name: 'program-media-block',
  components: {
    StreamPlayer,
  },
  computed: {
    ...mapGetters({
      unpinnedMediaItems: 'promoProgramStore/unpinnedMediaItems',
    }),
  }
})
export default class ProgramMediaBlock extends Vue {

  @Prop({ type: Object })
  public readonly program: TConferenceProgram;

  public get programMediaItems(): TMediaItem[] {
    if (!this.program || !this.program.mediaItems) {
      return [];
    }
    return this.program.mediaItems;
  }

  public activeSlideIndex: number = 0;
  public mediaItems: TMediaItem[] = [];

  public mounted(): void {
    this.onCarouselItemChange(this.activeSlideIndex);
  }

  public onCarouselItemChange(index: number): void {
    this.activeSlideIndex = index;

    this.stopAllVideos();

    this.$nextTick(() => {
      if (!this.$refs.carouselItems) {
        return;
      }
      const activeCarouselItem: any = (this.$refs.carouselItems as any[]).find((component: any) => {
        return !!component.active;
      });
      if (!activeCarouselItem) {
        return;
      }
      const activeCarouselItemId = activeCarouselItem.$el.getAttribute('id');
      const activeMediaItem = this.programMediaItems.find((mediaItem: TMediaItem) => {
        return mediaItem.id === activeCarouselItemId;
      });

      if (activeMediaItem) {
        this.$store.dispatch('promoProgramStore/setActiveMediaItem', {
          programId: this.program.id,
          id: activeMediaItem.id,
        });
      }
    });
  }

  public toggleVideoPlay(event: MouseEvent): void {
    let vidEl = event.target as HTMLVideoElement;
    if (vidEl.tagName.toLowerCase() !== 'video') {
      vidEl = vidEl.childNodes[0] as HTMLVideoElement;
    }
    try {
      if (vidEl.paused) {
        vidEl.play();
      } else {
        vidEl.pause();
      }
    } catch (error) {
      // ?
    }
  }

  public onVideoPlayPauseChange(/* item: TMediaItem, isPaused: boolean */): void {
    // TODO:
    // this.$store.dispatch('promoProgramStore/setMediaItemIsPaused', {
    //   externalId: this.externalId,
    //   id: item.id,
    //   isPaused: isPaused,
    // });
  }

  @Watch('unpinnedMediaItems', { immediate: false })
  private onUnpinnedMediaItemsChange(): void {
    this.onCarouselItemChange(this.activeSlideIndex);
  }

  @Watch('programMediaItems', { immediate: true, deep: true })
  private onProgramMediaItemsChange(): void {

    if (!this.program.mediaItems) {
      this.mediaItems = [];
    } else {
      this.mediaItems = [ ...this.program.mediaItems.filter((mediaItem: TMediaItem) => {
        return !mediaItem.isUnpinned;
      }) ];
    }

    if (this.activeSlideIndex > this.mediaItems.length - 1) {
      this.activeSlideIndex = Math.max(0, this.mediaItems.length - 1);
    }
  }

  private stopAllVideos(): void {
    const allVideos = this.$refs.videoSlideContents as Element[];
    if (!allVideos) { // AW-413
      return;
    }
    for (const video of allVideos) {
      (video as HTMLVideoElement).pause();
    }
    // TODO:
    // this.$store.dispatch('promoProgramStore/setAllVideoMediaItemsIsPaused', {
    //   externalId: this.externalId,
    // });
  }
}

